<template>
    <div class="subscribe-list-main">
        <!-- <van-tabs v-model="activeIndex" class="mytab" @change="tabChange" :title-style="tabStyle" color="#298AFB" >
            <van-tab title="预约中" name="wait">

            </van-tab>
            <van-tab title="已住院" name="zhuyuan">
                
            </van-tab>
        </van-tabs> -->
        <div class="subscrube-list">
            <!--  -->
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="tableData.length != 0">
                    <div  class="head-item" v-show="tableData.length != 0">
                        <img src="@/assets/img/subscribe-head-icon.png" alt="">
                        <div class="text">
                            <p class="title">{{$t("subscribe.desc1")}}</p>
                            <p class="desc">{{$t("subscribe.desc2")}}</p>
                        </div>
                    </div>
                    <div v-for="(item,index) in tableData" :key="index" @click="goDetail(item)"  class="subscribeItem">
                        <div class="user-info">
                            <p class="info-details">
                                <span class="name">{{item.patientName}}</span>
                                <span class="sex" :class="item.gender == 'male'?'man':'woman'"></span>
                                <span class="status" :class="'hospotal'+item.yuyueZhuyuanStatus">{{item.yuyueZhuyuanStatus == 143001?'处理中':item.yuyueZhuyuanStatus == 143002?'预约成功':item.yuyueZhuyuanStatus == 143003?'取消':'驳回'}}</span>
                            </p>
                            <p class="order-infoitem">
                                <img src="@/assets/img/time-icon.png" class="lable" alt="">
                                <span class="txt">{{$t("subscribe.subscribeTime")}}：{{getLocalDateTime(item.planInHospDt)}}</span>
                            </p>
                        </div>
                        <van-icon name="arrow icon"   />
                        <!-- <a :href="'tel:'+item.depTel" v-if="item.depTel" class="user-phone">
                            <div class="user-phone">
                                <img src="@/assets/img/contact-phone.png" alt="">
                                <p class="txt">联系医院</p>
                            </div>
                        </a> -->
                    </div>
            </van-pull-refresh>
            
            <div class="data-null" v-if="tableData.length == 0">
                <img src="@/assets/img/subscrubenull.png" >
                <p class="txt">{{$t("subscribe.noHosptail")}}</p>
                <div class="add-btn" @click="goSubscribe">{{$t("subscribe.immediatelySubscribe")}}</div>
            </div>
        </div>
        <div class="addSubscribe" v-if="tableData.length != 0" @click="goSubscribe">
            <van-icon name="plus" />
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant';
import { getLocalTime, getLocalDateTime } from "@/utils/date";
import { Inhospitalquery } from "@/api/index"
export default {
    data(){
        return{
            tableData:[],
            getLocalTime,
            getLocalDateTime,
            loading: false,
            finished: false,
            refreshing: false,
            activeIndex:"wait",
            pageNum:1,
            tabStyle:{
                fontSize:"0.64rem"
            }
        }
    },
    created () {
        this.getSubscribeData();
        document.title = this.$t("appointment.myAppointment")
    },
    methods:{
        goSubscribe(){
            this.$router.push({path:"/subscribeHospital/addSubscribe"})
        },
        tabChange(){
            this.finished = false;
            this.pageNum = 1;
            this.refreshing = false;
            // this.getSubscribeData();
            // this.onLoad();
        },
        async getSubscribeData(){
            // 
            const toast = Toast.loading({
                message: this.$t("message.loadingTxt"),
                forbidClick: true,
            });
            let datadd = {
                status:this.activeIndex
            }
            let result = await Inhospitalquery(datadd);
            toast.clear();
            if(this.refreshing){
                this.refreshing = false;
            }
            this.loading = false;
            if(result.data.errorCode == 0){
                // this.$toast.success("预约成功");
                // this.$router.go(-1);
                this.tableData = result.data.data;
            }else{
                if(this.pageNum == 1){
                    this.tableData = [];
                }
            }
        },
        onRefresh(){
            this.finished = false;
            this.pageNum = 1;
            this.loading = true;
            this.getSubscribeData();
        },
        goDetail(item){
            if(item.yuyueZhuyuanStatus == 143001){
                this.$router.push({
                    path:"/subscribeHospital/addSubscribe",
                    query:{
                        yuyueZhuyuanId:item.yuyueZhuyuanId
                    }
                })
            }else{
                this.$router.push({
                    path:"/subscribeHospital/result",
                    query:{
                        yuyueZhuyuanId:item.yuyueZhuyuanId
                    }
                })
            }
        },

    }
}
</script>

<style lang="less" scoped>
.subscribe-list-main{
    display: flex;
    flex-direction: column;
    background-color: #F6F7FB;
    height: 100%;
    .addSubscribe{
        position: fixed;
        width: 1.92rem;
        height: 1.92rem;
        border-radius: 50%;
        background: #298AFB;
        right: 0.6rem;
        bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .van-icon-plus{
            color: #fff;
            font-size: 0.8rem;
            font-weight: bold;
        }
    }
    .van-pull-refresh{
        min-height: 100vh;
    }
    /deep/ .mytab{
        flex: auto 0 0;
        height: 2rem;
        background-color: #fff;
        .van-tabs__wrap{
            min-height: 100%;
            .van-tab{
                font-size: 0.68rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                
                color: #9B9B9B;
                .van-tab__text {
                    height: 100%;
                    line-height: 2rem;
                }
            }
            .van-tab--active{
                color: #181818;
            }
            .van-tabs__line{
                width: 2rem;
            }
        }
    }
    .subscrube-list{
        flex: 1;
        overflow: auto;
            .van-cell{
                background: none;
            }
             /deep/ .van-list__finished-text{
                font-size: 0.6rem;
            }
            .head-item{
                display: flex;
                padding: 0.7rem 0.6rem;
                align-items: center;
                img{
                    width: 1.8rem;
                    height: 1.8rem;
                    flex: auto 0 0;
                    margin-right: 0.4rem;
                }
                .text{
                    flex: 1;
                    .title{
                        font-size: 0.6rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #181818;
                        line-height: 0.8rem;
                    }
                    .desc{
                        font-size: 0.44rem;
                        margin-top: 0.1rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9B9B9B;
                        line-height: 0.6rem;
                    }
                }
            }
            .subscribeItem{
                margin: 0 0.6rem 0.4rem 0.6rem;
                background-color: #fff;
                border-radius: 0.24rem;
                padding: 0.6rem;
                display: flex;
                align-items: center;
                .user-info{
                    flex: 1;
                    .info-details{
                        display: flex;
                        align-items: center;
                        margin-bottom: 0.4rem;
                        span{
                            margin-right: 0.3rem;
                            &:last-child{
                                font-size: 0.44rem;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #FFFFFF;
                                margin-left: 0.1rem;
                                padding: 0.06rem 0.34rem;
                                // background-color: #FFC00F;
                                line-height: 0.6rem;
                            }
                        }
                        .status{
                            border-radius: 0.1rem;
                        }
                        .hospotal143001{
                            background-color: #FF8900;
                        }
                        .hospotal143002{
                            background-color: #49A7FF;

                        }
                        .hospotal143003{
                            background-color: #909399;
                        }
                        .hospotal143004{
                            background-color: #FF3535;
                        }   
                        .sex{
                            width: 0.6rem;
                            height: 0.6rem;
                            background: url("~@/assets/img/man-icon.png") no-repeat;
                            background-size: 100% 100%;
                        }
                        .woman{
                            background: url("~@/assets/img/woman-icon.png") no-repeat;
                            background-size: 100% 100%;
                        }
                        .name{
                            font-size: 0.72rem;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #181818;
                            line-height: 1rem;
                        }
                    }
                    .order-infoitem{
                        margin-bottom: 0.3rem;
                        display: flex;
                        align-items: center;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        img{
                            width: 0.4rem;
                            height: 0.4rem;
                            margin-right: 0.3rem;
                        }
                        .txt{
                            font-size: 0.52rem;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #696969;
                            line-height: 0.74rem;
                            
                        }
                    }
                }
                /deep/ .van-icon-arrow {
                    font-size: 0.7rem;
                    color: #A8A8AB;
                }
                .user-phone{
                    flex: auto 0 0;
                    margin-left: 0.2rem;
                    img{
                        display: block;
                        margin: 0 auto;
                        width: 1.6rem;
                        height: 1.6rem;
                    }
                }
            }
        .data-null{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            img{
                width: 6rem;
                height: 4rem;
            }
            .txt{
                font-size: 0.56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
                line-height: 0.8rem;
                margin: 0.6rem auto 0.8rem auto;
            }
            .add-btn{
                height: 1.8rem;
                width: 12.8rem;
                margin: 1rem auto;
                background: #298AFB;
                border-radius: 0.9rem;
                color: #fff;
                font-size: 0.72rem;
                line-height: 1.8rem;
                text-align: center;
            }
        }
    }
}
</style>